import axios, { AxiosInstance } from "axios";
import qs from "qs";
import { ref } from "vue";

let api: AxiosInstance;

export function useApi() {       

    const initApi = (baseUrl: string) => {
        api = axios.create({
            baseURL: baseUrl,
            headers: { "Content-type": "application/json" },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
        });
    };

    return {
        initApi, api
    }
}