import { computed } from "vue";
import useSurveyPlatform from "./useSurveyPlatform"

const platform = useSurveyPlatform();

export default function useCookieStandardPolicy() {    
    const standardPolicyUrl = computed(() => platform.getPlatformUrlFromPath("/assets/html/cookie_policy.html"));

    return {
        standardPolicyUrl
    }
}