import AppCustomizationsModel from "@/models/AppCustomizationsModel"
import appCustomizationsService from "@/services/AppCustomizationsService"

export function useAppCustomizations() {       
    const getAppCustomizations = (): Promise<AppCustomizationsModel> => {
        return appCustomizationsService.getCustomizations();
    }

    return {
        getAppCustomizations
    }    
}