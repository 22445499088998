import { ref } from "vue"
const platformBaseUrl = ref<string | null>(null);

export default function useSurveyPlatform() {
    
    const initPlatform = (platformUrl: string) => {
        platformBaseUrl.value = platformUrl;

        if (!platformBaseUrl.value?.endsWith("/")) {
            platformBaseUrl.value += "/";
        }
    }

    const getPlatformUrlFromPath = (path: string) => {
        if (path.startsWith("/")) {
            return platformBaseUrl.value + path.substring(1, path.length);
        }
        
        return platformBaseUrl.value + path;
    }

    return {
        initPlatform, platformBaseUrl, getPlatformUrlFromPath
    }
}