import { useApi } from '@/composables/useApi';
import { AxiosInstance } from 'axios';

export class BaseService {
    get api(): AxiosInstance {
        const { api } = useApi();

        return api;
    };

    tryExecute = async <T> (func: Promise<T>) : Promise<T> => {
        const result = await func;

        return result;
    };    
}