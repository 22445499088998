import { computed } from "vue";

let cookiebotId: string;

export function useCookiebot() {
    const initCookiebot = (siteCookiebotId: string) => {
        cookiebotId = siteCookiebotId;
    }

    const isEnabled = computed(() => !!cookiebotId && cookiebotId.trim() != '');

    return {
        initCookiebot, cookiebotId, isEnabled
    }
}