import { createApp } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import { useApi } from "@/composables/useApi";
import App from './App.vue';
import PrimeVue from 'primevue/config';
import Card from 'primevue/card';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Slider from 'primevue/slider';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message'
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ProgressSpinner from 'primevue/progressspinner';
import Image from 'primevue/image';

import Header from '@/components/Header.vue'
import Survey from '@/components/Survey.vue'

//import 'primevue/resources/themes/fluent-light/theme.css';
import "primeflex/primeflex.css";
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { useRecaptcha } from './composables/useRecaptcha';
import { useCookiebot } from './composables/useCookiebot';
import useSurveyPlatform from './composables/useSurveyPlatform';

const { initApi } = useApi();
const { initRecaptcha } = useRecaptcha();
const { initCookiebot } = useCookiebot();
const { initPlatform } = useSurveyPlatform();

initPlatform(process.env.VUE_APP_PLATFORM_BASE_URL);
initApi(process.env.VUE_APP_API_BASE_URL);
initRecaptcha(process.env.VUE_APP_RECAPTCHA_SITE_KEY);
initCookiebot(process.env.VUE_APP_COOKIEBOT_ID);

const routes: any = [
    { path: "/", component: Survey },
    { path: "/:path", component: Survey }
  ];
  
const router = createRouter({
    history: createWebHistory(),
    routes
  });  

const app = createApp(App);

app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(router);

// primevue
app.component('Card', Card);
app.component('Button', Button);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('SelectButton', SelectButton);
app.component('Textarea', Textarea);
app.component('AutoComplete', AutoComplete);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('Calendar', Calendar);
app.component('Slider', Slider);
app.component('Checkbox', Checkbox);
app.component('RadioButton', RadioButton);
app.component('Dialog', Dialog);
app.component('Message', Message);
app.component('Toast', Toast);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Image', Image);

// survey web app
app.component('Header', Header);
app.component('Survey', Survey);

app.mount('#app');