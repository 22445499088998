import AppCustomizationsModel from "@/models/AppCustomizationsModel";
import { BaseService } from "./BaseService";

class AppCustomizationsService extends BaseService {
    async getCustomizations() : Promise<AppCustomizationsModel> {
        const response = await this.tryExecute(this.api.get<AppCustomizationsModel>(`web-surveys/app-customizations`));

        return response.data;
    }
}

export default new AppCustomizationsService();