import surveyService from "@/services/SurveyService";

export function useSurvey() {
    const fetchSurvey = async (id: string) : Promise<any> => {
        return await surveyService.getSurvey(id);
    }

    const getLookups = async () : Promise<any> => {
        return await surveyService.getLookups();
    }

    const sendSubmission = async (id: string, recaptchaToken: string, submission: any): Promise<any> => {
        return await surveyService.sendSubmission(id, recaptchaToken, submission);
    }

    const checkUniqueAnswer = async (surveyId: number, questionId: number, answer: any): Promise<boolean> => {
        return await surveyService.checkUniqueAnswer(surveyId, questionId, answer);
    }

    return { fetchSurvey, getLookups, sendSubmission, checkUniqueAnswer }
}