import { BaseService } from "./BaseService";

class SurveyService extends BaseService {
    async getSurvey(id: string) : Promise<any> {
        const response = await this.tryExecute(this.api.get<any>(`web-surveys/${id}`));

        return response.data;
    }

    async getLookups() : Promise<any> {
        const response = await this.tryExecute(this.api.get<any>(`web-surveys/lookups`));

        return response.data;
    }

    async sendSubmission(id: string, recaptchaToken: string, submission: object) : Promise<any> {
        const response = await this.tryExecute(this.api.post<any>(`web-surveys/${id}?token=${recaptchaToken}`, submission));

        return response.data;
    }

    async checkUniqueAnswer(surveyId: number, questionId: number, answer: any) : Promise<boolean> {
        const response = await this.tryExecute(this.api.post<boolean>(`web-surveys/validations/check-unique/${surveyId}/${questionId}`,
                                                answer));

        return response.data;
    }

    async downloadQuestionAttachment(fileName: string): Promise<any> {
        const response = await this.tryExecute(this.api.get<any>(`web-surveys/download-attachment?fileName=${fileName}`, { responseType: "blob" }));
        return response.data;
    }
}

export default new SurveyService();