let recaptchaSiteKey: string;

export function useRecaptcha() {       
    const initRecaptcha = (siteKey: string) => {
        recaptchaSiteKey = siteKey
    };

    return {
        recaptchaSiteKey, initRecaptcha
    }
}